<template>
    <div class="container">
        <modal-work-order-employee inputId="wo-employee-modal" :workOrderEmployee="selectedWorkOrderEmployee"/>
        <div class="table-wrapper">
            <div class="table-title">
                <div class="row">
                    <div class="col-sm-5">
                        <h2>Radnici</h2>
                    </div>
                    <div class="col-sm-7">
                        <a href="#" class="btn btn-secondary" v-on:click="selectWorkOrderEmployee(null)" data-bs-toggle="modal" data-bs-target="#wo-employee-modal"><MenuIcon class="icon-2x" /><span class="btn-title">Dodaj novog radnika</span></a>
                        <!-- <a href="#" class="btn btn-secondary"><FileExcel /><span class="btn-title">Export Excel</span></a>						 -->
                    </div>
                </div>
            </div>
            <div>
                <table class="table table-striped table-hover">
                    <thead>
                    <!-- <h5>Radnici</h5> -->
                        <tr>
                            <th>Tip radnika</th>
                            <th>Tim</th>
                            <th>Ime</th>
                            <th>Početak</th>
                            <th>Završetak</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employee in workOrderEmployees" :key="employee.id">
                            <td>{{ employeeLabel(employee.role) }}</td>
                            <td>{{ employee.team_name }}</td>
                            <td>{{ employee.first_name }} {{ employee.last_name }}</td>
                            <td>{{ employee.start_date }}</td>
                            <td>
                                {{ employee.end_date }}
                            </td>
                            <td>
                                <button v-on:click="selectWorkOrderEmployee(employee)" data-bs-toggle="modal" data-bs-target="#wo-employee-modal" class="btn btn-block btn-outline-warning">Izmeni</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
import MenuIcon from 'vue-material-design-icons/Menu.vue';
// import FileExcel from 'vue-material-design-icons/FileExcel.vue';
import {mapState} from 'vuex'
import UtilService from '@/service/UtilService';
import ModalWorkOrderEmployee from '@/components/modals/ModalWorkOrderEmployee.vue';

export default {
    components: {
        MenuIcon,
        ModalWorkOrderEmployee,
        // FileExcel,
    },

    data: function() {
        return {
            selectedWorkOrderEmployee: null,
        }
    },

    computed: {
        ...mapState([
        'workOrderEmployees',
        ]),
        workOrderID() {
            return this.$route.params.woId
        }
    },

    methods: {
        employeeLabel: UtilService.employeeRoleLabel,

        loadWorkOrderEmployees(id){
            this.$store.dispatch('loadWorkOrderEmployees', {workOrderId: id});
        },

        selectWorkOrderEmployee(id) {
            this.selectedWorkOrderEmployee = id;
        }
    },
    mounted() {
        this.loadWorkOrderEmployees(this.workOrderID)
    }


}
    
</script>

<style>

    .table-title {
        padding-bottom: 15px;
        background: #299be4;
        color: #fff;
        padding: 16px 30px;
        margin: 0 -25px 10px;
        border-radius: 3px 3px 0 0;
    }

    .table-title h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }

    .table-title .btn {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        margin-left: 10px;
    }

    .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .table-title .btn .btn-title {
        margin-top: 20px;
    }

    .table-wrapper {
        min-width: 1000px;
        background: #fff;
        padding: 20px 25px;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    }


    .table td, .table th {
        padding: .75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    table.table-striped tbody tr:nth-of-type(odd) {
        background-color: #fcfcfc;
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0,0,0,.05);
    }

    table.table-striped.table-hover tbody tr:hover {
        background: #f5f5f5;
    }
    table.table thead {
        font-size: 0.8em
    }

</style>