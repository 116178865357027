<template>
      <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
               Dodaj novi radni nalog
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="col-12">
                <label for="inputEmployee" class="form-label">Izaberi zaposlenog</label>
                <v-select
                    :inputId="'inputEmployee'"
                    :options="allEmployees"
                    :reduce="col => col.id"
                    label="firstName" v-model="workOrderEmployeeForm.employeeId"
                    :selectable="option => true" 
                    :clearable = "false"
                    class="mb-3" id="inputEmployee"
                    >
                    <template v-slot:option="option">
                        <h5 class="m-0">{{ option.firstName }} {{ option.lastName }} ( {{ option.username }} )</h5>
                        <div v-if="option.occupations != undefined">
                            <p v-for="(occupation, index) in option.occupations" :key="index">Zauzet od <span class="badge bg-warning">{{formatDateTime(occupation.start)}}</span></p>
                        </div>
                    </template>
                </v-select>

            </div>

            <div class="mb-3 form-floating">
                <input type="text" class="form-control" id="woeTeam" v-model="workOrderEmployeeForm.teamName" placeholder="Tim">
                <label for="woeTeam">Tim</label>
            </div>
            <div class="mb-3 form-floating">
                <input type="date" class="form-control" id="woeStartDate" v-model="workOrderEmployeeForm.startDate" placeholder="Počeo sa radom">
                <label for="woeStartDate">Počeo sa radom</label>
            </div>
            <div class="mb-3 form-floating">
                <input type="date" class="form-control" id="woeEndDate" v-model="workOrderEmployeeForm.endDate" placeholder="Završio sa radom">
                <label for="woeEndDate">Završio sa radom</label>
            </div>
            
            <div class="mb-3 form-floating">
                <select type="text" class="form-control" id="woeEndDate" v-model="workOrderEmployeeForm.role" placeholder="Uloga">
                    <option value="WORKER">Radnik</option>
                    <option value="MANAGER_II">Menadžer II reda</option>
                    <option value="MANAGER_I">Menadžer I reda</option>
                </select>
                <label for="woeEndDate">Uloga</label>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" v-on:click="createWorkOrderEmployee" >{{workOrderEmployee?'Izmeni':'Dodaj'}}</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    
    components: {
    },

    props: {
        inputId: {
            type: String,
            default: "",
        },

        workOrderEmployee: {
            type: Object,
            default: null,
        }
    },

    data: function() {
        return {

            workOrderEmployeeForm: {
                id: null,
                deleted: false,
            },
        }
    },

    watch: {
        'workOrderEmployee' : function() {
            this.workOrderEmployeeForm = {
                id: null,
                deleted: false,
            }
            this.loadPropData();
        }
    },

    computed: {
        ...mapState([
            'allEmployees',
        ]),
        workOrderId() {
            return this.$route.params.woId
        }
    },

    methods: {
        loadAllEmployees(){
                this.$store.dispatch('loadAllEmployees', this.workOrderForm);

        },

        async createWorkOrderEmployee() {
            this.workOrderEmployeeForm.workOrderId = this.workOrderId;

            if (this.workOrderEmployee)
                await this.$store.dispatch('updateWorkOrderEmployee', this.workOrderEmployeeForm);
            else
                await this.$store.dispatch('createWorkOrderEmployee', this.workOrderEmployeeForm);

            this.loadWorkOrderEmployees(this.workOrderId);
        },

        loadPropData(){
            if (this.workOrderEmployee){

                this.workOrderEmployeeForm.id = this.workOrderEmployee.id;
                this.workOrderEmployeeForm.employee = this.workOrderEmployee.employee;

                this.workOrderEmployeeForm.teamName = this.workOrderEmployee.teamName;
                this.workOrderEmployeeForm.startDate = this.workOrderEmployee.startDate;
                this.workOrderEmployeeForm.endDate = this.workOrderEmployee.endDate;
                this.workOrderEmployeeForm.role = this.workOrderEmployee.role;


                this.workOrderEmployeeForm.deleted = this.workOrderEmployee.deleted;
            }
        },

        loadWorkOrderEmployees(id){
            this.$store.dispatch('loadWorkOrderEmployees', {workOrderId: id});
        },

        
    },

    mounted() {
        this.loadAllEmployees();
        this.loadPropData();


    },

}
</script>

<style>

</style>