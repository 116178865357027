<template>
  <div class="worker-order-employees">
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <WorkOrderEmployees />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Navbar from '@/components/Navbar.vue'
import WorkOrderEmployees from '@/components/WorkOrderEmployees.vue'
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    WorkOrderEmployees,
    Footer
  }
}
</script>
